.center {
    text-align: center;
}

.p-t-15 {
    padding-top: 15px;
}

.p-b-15 {
    padding-bottom: 15px;
}

.m-l-15 {
    margin-left: 15px;
}

.justify {
    text-align: justify;
}

ol {
    padding-top: 5px;
    padding-bottom: 5px;
}

ol p {
    padding-bottom: 5px;
}

li {
    padding-left: 15px;
}