.feed__inputOptions {
    display: flex;
    justify-content: space-evenly;
}

.input-Option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 5px;
    padding: 5px 5px;
    /* cursor: pointer; */
}

.input__title {
    font-size: 12px;
    padding-top: 3px;
}

.like-hover:hover {
    width: 25px !important;
    transition: 2s !important;
}

.like_display {
    display: block;
}

.share_display {
    display: none;
}

/* by reactpdf */

/* .pdf_view {
    margin-top: 40px;
  } */

.react-pdf__Document {
    max-width: 100%;
    position: relative;
}

.react-pdf__Page {
    max-width: 100%;
    /* width: 90vw; */
}

.react-pdf__Page__canvas {
    width: 60% !important;
    height: 28% !important;
    object-fit: cover !important;
    user-select: all !important;
    overflow: scroll !important;
    margin: auto !important;
}



.pdf_post>.react-pdf__Page>.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: fill !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 0px !important;
    max-height: 560px !important;
}

/* .pdf_route>.react-pdf__Page>.react-pdf__Page__canvas {
    width: 100% !important;
    height: 600px !important;
    object-fit: cover !important;
    display: flex !important;
    justify-content: center;
  } */

/* .react-pdf__Page__svg {
    width: auto !important;
    height: auto !important;
  }
  
  .react-pdf__Page__svg svg {
    border-radius: 8px;
    max-width: 400px;
    width: 90vw;
    height: auto;
    display: block;
    background-color: white;
  }
  
  .react-pdf__Page__textContent {
    position: absolute;
    top: 20% !important;
    left: 20% !important;
    width: 100% !important;
    height: 500px !important;
    color: transparent;
    transform: translate(-50%, -50%) rotate(0deg);
    pointer-events: none;
  } */


/* .react-pdf__Page__canvas {
  width: 100% !important;
  height: 400px !important;
  }
  
  
  
  
  
  
  /* sandboxcode */

/* .react-pdf__Page {
    margin-top: 10px;
  }
  
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
  } */
/* 
  .react-pdf__Page {
    margin-top: 10px;
    width: 100%;
    height: 100%;
  }
  
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 70% !important;
    height: 600px !important;
  } */




.react-pdf__Page__annotations {
    display: none !important;
}

.bg_transperency {
    display: flex !important;
    justify-content: space-between !important;
}

.bg_transperency1 {
    display: flex !important;
    justify-content: space-between !important;
    margin: 0px 5rem !important;
}

.buttondisable {
    border-radius: 12px !important;
    height: 30px !important;
    color: grey !important;
    font-weight: 600 !important;
    /* width: 120px !important; */
    margin-right: 11px !important;
    text-transform: capitalize !important;
    background-color: #fff !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px !important;
}

.buttonc {
    border-radius: 12px !important;
    height: 30px !important;
    color: black !important;
    font-weight: 600 !important;
    /* width: 120px !important; */
    margin-right: 11px !important;
    text-transform: capitalize !important;
    background-color: #fff !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px !important;
}

@media screen and (max-width: 1025px) {
    .pdf_post>.react-pdf__Page>.react-pdf__Page__canvas {
        width: 100% !important;
        height: 500px !important;
        object-fit: cover !important;
        display: flex !important;
        justify-content: center !important;
        margin-top: 0px !important;
    }
}


@media screen and (max-width:769px) {
    .react-pdf__Page__canvas {
        width: 100% !important;
        height: 800px !important;
        object-fit: contain !important;
        user-select: all !important;
        overflow: scroll !important;
        margin: auto !important;
    }

    .pdf_post>.react-pdf__Page>.react-pdf__Page__canvas {
        width: 100% !important;
        height: 500px !important;
        object-fit: cover !important;
        display: flex !important;
        justify-content: center !important;
        margin-top: 0px !important;
    }
}

@media screen and (max-width: 426px) {
    .like_display {
        display: none;
    }

    .share_display {
        display: block;
    }

    .pdf_post>.react-pdf__Page>.react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
        display: flex !important;
        justify-content: center !important;
        margin-top: 0px !important;
    }

    .react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
        user-select: all !important;
        overflow: scroll !important;
        margin: auto !important;
    }

    .bg_transperency1 {
        display: flex !important;
        justify-content: space-between !important;
        margin: 0px !important;
    }

    .react-pdf__Page {
        max-width: 100%;
        height: 100%;
    }

}