* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

/* hiding vertical scrollbar */
body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}