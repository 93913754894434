/* padding */
.sidebar__pb {
  padding-bottom: 10px;
}

.sidebar__pt {
  padding-top: 10px;
}

.ftsz-18 {
  font-size: 18px;
}

.mt-15 {
  margin-top: 15px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pl-3 {
  padding-left: 30px;
}

.pl-1 {
  padding-left: 10px;
}

.pl-2 {
  padding-left: 20px;
}

.pr-2 {
  padding-right: 20px;
}

.pl_5 {
  padding-left: 5px;
}

.sidebar__padding {
  padding: 10px 20px;
}

.im {
  margin: 0px 5px;
}

/* margin */
.sidebar__mb {
  margin-bottom: 10px;
}

.sidebar__ml {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-1 {
  margin-left: 15px;
}

.ml-5 {
  margin-left: 5px;
}

.sidebar__mt {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px;
}

.cursor {
  cursor: pointer;
}

/* font */
.font-head {
  font-size: 24px;
}

.font-caption {
  font-size: 14px;
}

.font-title {
  font-size: 12px;
}

.font-sm {
  font-size: 10px;
}

/* fontweight */
.fw__semibold {
  font-weight: 600;
}

.fw__bold {
  font-weight: bold;
}

.fw__normal {
  font-weight: normal;
}

.sidebar__tmb {
  margin-bottom: 20px;
}

.sidebar__lh {
  line-height: 17px;
}

.sidebar__tle {
  line-height: 15px;
  font-weight: 600;
  margin-left: 10px;
}

/* icons */
.icon__ml {
  margin-left: 5px;
}

.flex__col {
  display: flex;
  flex-direction: column;
}

.jc__center {
  justify-content: center;
}

/* display */
.d-flex {
  display: flex;
}

.flex__end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/* border */
.border__bottom {
  border-bottom: 1px solid lightgray;
}

/* color */
.primary {
  background-color: #0077c4;
}

.white {
  color: #ffffff;
}

.bg__white {
  background-color: white;
}

.bg__teal {
  background-color: #01cde5;
}

.bg__blue {
  background-color: #d8e8f1;
}

/* container */
.container {
  display: flex;
  margin-top: 24px;
}

/* border-radius */
/* width */
.width__100 {
  width: 100%;
}

.bg_dark_blue {
  background-color: #257fc4;
}

.space_btw_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_center {
  display: flex;
  align-items: center;
}

.space_around_center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.space_evenly_center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* Text alignments */
.ta__center {
  align-items: center;
}

.ta__left {
  align-items: center;
}

/* height */
.height {
  height: 100px;
}

.h-250 {
  height: 250px;
}

/* btn */
.btn-bgwhite {
  border: 1px solid #106dbe !important;
  color: #106dbe !important;
  border-radius: 15px !important;
  padding: 4px 32px !important;
  background-color: white;
  font-size: 12px;
  text-transform: capitalize !important;
}

@media screen and (max-width: 769px) {
  .bg__blue {
    background-color: white;
  }
}

@media screen and (max-width: 426px) {
  .h-250 {
    height: 200px;
  }
}