.nav-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    gap: 2
}

.subtext {
    position: absolute;
    top: -6px;
    left: 2px;
    background: white;
    z-index: 1;
    padding: 0 5px;
    font-size: 10px;
    color: #3d6596;
    /* border: 1px solid yellow; */
    /* width: 110px; */
}

.nav-desk {
    display: none;
}

@media only screen and (min-width: 900px) {
    .nav-mob {
        display: none;
    }

    .nav-desk {
        display: block;
        /* box-shadow: 1px 1px 4px #333; */
    }

    .navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .navbar li {
        position: relative;
    }

    .subtext {
        position: absolute;
        top: 1px;
        left: 4px;
        background: white;
        z-index: 1;
        /* padding: 0 5px; */
        font-size: 12px;
        color: #3d6596;
        /* border: 1px solid red; */
        /* width: 147px; */
        /* width: 100px; */


    }
}