* {
    text-decoration: none;
}

.navlinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    width: 100%;
    justify-content: space-around;
}

.activeitem {
    border-bottom: 3px solid #205072;
    font-weight: bold;
    transition: all 0.1s ease-in-out;
    padding-bottom: 2px;
}

.navoptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #007dcd;
}